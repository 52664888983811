import axios from "axios";
import axiosRetry from "axios-retry";
import ajaxUtil from "./ajax_helper_utils";

let url = process.env.REACT_APP_URL;
if (!url) {
  url = "https://apibeta.meditutor.net";
}

axiosRetry(axios, {
  retries: 2,
  retryCondition: (error) => {
    if (error.config.url.includes("localhost:8050")) {
      return false;
    }
  },
});

const ajaxHelper = (action) => {
  const req = ajaxUtil.genRequest(action.request);
  req.url = url.trim() + req.url;
  const transport = axios.create({
    withCredentials: true,
  });
  return transport.request(req);
};

export default ajaxHelper;
