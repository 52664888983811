import React from "react";
import LogRocket from "logrocket";
import setupLogRocketReact from "logrocket-react";
import GitInfo from "react-git-info/macro";
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

import { render } from "react-dom";

// import { createStore, applyMiddleware, compose } from 'redux';
import { Provider } from "react-redux";
import { Router, Route, Switch, Redirect } from "react-router-dom";
import { history } from "./history";
import loadable from "@loadable/component";
import LoadingComponent from "./components/common/loading";
import LoginPage from "./components/login/LoginPage";
import configureStore from "./store/configureStore";

import * as serviceWorker from "./serviceWorker";

const css = require.context("./css", true, /.*/);
css.keys().forEach(css);
// Main Container
const MainContainer = loadable(
  () => import("./components/common/MainContainer"),
  {
    fallback: LoadingComponent({
      pastDelay: true,
      error: false,
      timedOut: false,
    }),
  }
);

if (!process.env.NODE_ENV || process.env.NODE_ENV === "development") {
  const whyDidYouRender = require("@welldone-software/why-did-you-render");
  const ReactRedux = require("react-redux");
  whyDidYouRender(React, {
    trackAllPureComponents: true,
  });
  // const firebaseConfig = {
  //   apiKey: "AIzaSyDl4aLHj21kCPLCtOORddZVqARZtGkcSRA",
  //   projectId: "meditutor-b4ca0",
  //   appId: "1:433250490518:web:b37964fd5259c0865b0ab7",
  //   measurementId: "G-YM05RD8PF5"
  // };
  // firebase.initializeApp(firebaseConfig);
  // firebase.analytics();
  console.log("we in dev mode :)");
} else {
  LogRocket.init("meditouch/meditutor", {
    release: GitInfo().commit.shortHash,
    console: {
      isEnabled: {
        log: true,
        debug: false,
      },
      shouldAggregateConsoleErrors: true,
    },
    network: {
      requestSanitizer: (request) => {
        // if the url contains 'ignore'
        if (request.url.toLowerCase().indexOf("localhost") !== -1) {
          // scrub out the body
          request.body = null;
        }

        return request;
      },
    },
  });
  setupLogRocketReact(LogRocket);

  const firebaseConfig = {
    apiKey: "AIzaSyC_GLoUenhSvsab1G4Gow-1TiI0iVUDtOw",
    projectId: "meditutor-frontend-prod",
    appId: "1:543359945217:web:c54d7bcc9e57845e3d4e00",
    measurementId: "G-VSMBYT31G2",
  };
  const app = initializeApp(firebaseConfig);
  const analytics = getAnalytics(app);
}

/* create Store */
const store = configureStore();

render(
  <Provider store={store}>
    <Router history={history}>
      <Switch>
        <Route exact path="/">
          <Redirect to="/login" />
        </Route>
        <Route path="/login">
          <LoginPage />
        </Route>
        <Route path="/main">
          <MainContainer />
        </Route>
      </Switch>
    </Router>
  </Provider>,
  document.getElementById("app")
);

serviceWorker.unregister();
